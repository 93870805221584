<template>
	<div class="sticky flex flex-col items-center self-start flex-1 w-full py-2 md:w-auto top-16">
		<NewsletterForm class="md:pl-6 md:pr-2" />
	</div>
</template>

<script async>
export default {
	components: {
		NewsletterForm: () => import('@/components/multiUse/NewsletterForm.vue')
	}
}
</script>
